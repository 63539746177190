/*!
 * loading
 */
if(document.querySelector('#loading')){
  var loader = document.querySelector('#loading');
  loader.style.display = "block";
  loader.classList.add("is-slide");
  /* 読み込み完了 */
  window.addEventListener('load', stopload);
  // /* 10秒経ったら強制的にロード画面を非表示にする */
  window.setTimeout(stopload,10000);
  /* ロード画面を非表示にする処理 */
  function stopload(){
      loader.classList.add('fadeout-loader');
  }
}