/*!
 * Collapse
 */

document.addEventListener("DOMContentLoaded", () => {
  const $element = Array.prototype.slice.call(
    document.querySelectorAll("[data-collapse]"),
    0
  );
  const elementTrigger = "dt";
  const elementContent = "dd";
  const classActive = "is-active";

  if ($element.length > 0) {
    $element.forEach((element) => {
      if (element.querySelectorAll(elementTrigger).length > 0) {
        const triger = element.querySelectorAll(elementTrigger);
        const content = element.querySelector(elementContent);
        if (window.getComputedStyle(content).display !== "none") {
          content.style.display = "none";
        }
        triger.forEach((button) => {
          button.addEventListener("click", (e) => {
            element.classList.toggle(classActive);
            slideToggle(content);
          });
        });
      }
    });
  }

  let slideUp = (target, duration = 100) => {
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + "ms";
    target.style.boxSizing = "border-box";
    target.style.height = target.offsetHeight + "px";
    target.offsetHeight;
    target.style.overflow = "hidden";
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    window.setTimeout(() => {
      target.style.display = "none";
      target.style.removeProperty("height");
      target.style.removeProperty("padding-top");
      target.style.removeProperty("padding-bottom");
      target.style.removeProperty("margin-top");
      target.style.removeProperty("margin-bottom");
      target.style.removeProperty("overflow");
      target.style.removeProperty("transition-duration");
      target.style.removeProperty("transition-property");
      //alert("!");
    }, duration);
  };

  let slideDown = (target, duration = 100) => {
    target.style.removeProperty("display");
    let display = window.getComputedStyle(target).display;

    if (display === "none") display = "block";

    target.style.display = display;
    let height = target.offsetHeight;
    target.style.overflow = "hidden";
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    target.offsetHeight;
    target.style.boxSizing = "border-box";
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + "ms";
    target.style.height = height + "px";
    target.style.removeProperty("padding-top");
    target.style.removeProperty("padding-bottom");
    target.style.removeProperty("margin-top");
    target.style.removeProperty("margin-bottom");
    window.setTimeout(() => {
      target.style.removeProperty("height");
      target.style.removeProperty("overflow");
      target.style.removeProperty("transition-duration");
      target.style.removeProperty("transition-property");
    }, duration);
  };

  let slideToggle = (target, duration = 100) => {
    if (window.getComputedStyle(target).display === "none") {
      return slideDown(target, duration);
    } else {
      return slideUp(target, duration);
    }
  };
});
