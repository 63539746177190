/*!
 * viewmore
 */

document.addEventListener("DOMContentLoaded", () => {
  const $element = Array.prototype.slice.call(
    document.querySelectorAll(".viewmore"),
    0
  );
  const elementTrigger = ".viewmore-button .button";
  const elementContent = ".viewmore-content";
  const classActive = "is-active";

  if ($element.length > 0) {
    $element.forEach((element) => {
      if (element.querySelectorAll(elementTrigger).length > 0) {
        const triger = element.querySelectorAll(elementTrigger);
        const content = element.querySelector(elementContent);
        triger.forEach((button) => {
          button.addEventListener("click", (e) => {
            content.classList.add(classActive);
          });
        });
      }
    });
  }
});
