/*!
 * Swiper
 * use : swiper-buldle.js
 */

function swiperAddButton(target, id) {
  document.querySelectorAll(target).forEach((element) => {
    element.insertAdjacentHTML(
      "afterend",
      '<span class="button is-circle swiper-button-next" id="' + id + '-next"><i class="icon-arrow"></i></span>' + '<span class="button is-circle swiper-button-prev" id="' +id + '-prev"><i class="icon-arrow"></i></span>'
    );
  });
}
document.addEventListener("DOMContentLoaded", () => {
  // new Swiper(".top-main-swiper", {
  //   loop: true,
  //   slidesPerView: "auto",
  //   spaceBetween: 0,
  //   effect: "fade",
  //   speed: 3000,
  //   fadeEffect: {
  //     crossFade: true,
  //   },
  //   autoplay: {
  //     delay: 3500,
  //   },
  //   pagination: {
  //     el: ".swiper-pagination",
  //   },
  // });

  //plan
  new Swiper(".swiper-type-02", {
    loopAdditionalSlides: 1,
    loop: false,
    slidesPerView: 1.3,
    spaceBetween: 20,
    speed: 500,
    centeredSlides: true,
    pagination: {
      el: ".swiper-pagination",
    },
  });

  //mv
  swiperAddButton("#swiper-mv", "mv");
  new Swiper(".swiper-mv", {
    loopAdditionalSlides: 1,
    loop: false,
    slidesPerView: 1.3,
    spaceBetween: 20,
    speed: 500,
    centeredSlides: true,
    navigation: {
      prevEl: "#mv-prev",
      nextEl: "#mv-next",
    },
    breakpoints: {
      // スライドの表示枚数：500px以上の場合
      768: {
        slidesPerView: 3.05,
        spaceBetween: 30,
        centeredSlides: false,
      }
    },
    on: {
      beforeInit: function () {
        const mv = document.querySelector(".swiper-mv>*");
        function shuffle(items) {
          var cached = items.slice(0),
            temp,
            i = cached.length,
            rand;
          while (--i) {
            rand = Math.floor(i * Math.random());
            temp = cached[rand];
            cached[rand] = cached[i];
            cached[i] = temp;
          }
          return cached;
        }

        var nodes = mv.children,
          i = 0;
        nodes = Array.prototype.slice.call(nodes);
        nodes = shuffle(nodes);

        // mv.innerHTML = "";

        while (i < mv.childElementCount) {
          mv.appendChild(nodes[i]);
          ++i;
        }
      },
    },
  });

  //thumbs
  const swiperThumbs = new Swiper(document.querySelector("#swiper-thumb"), {
    loop: false,
    slidesPerView: "auto",
    spaceBetween: 8,
    freeMode: true,
    watchSlidesProgress: true,
  });

  //main
  if ( document.querySelector("#swiper-main>.swiper-wrapper")){
    let options = {};
    if ( document.querySelector("#swiper-main>.swiper-wrapper").childElementCount == 1 ) {
      options = {
        loop: false,
        autoplay: false,
      }
      document.querySelector("#swiper-thumb").classList.add("is-hidden");
    } else {
      swiperAddButton("#swiper-main", "main");
      options = {
        loopAdditionalSlides: 1,
        loop: true,
        slidesPerView: 1,
        spaceBetween: 10,
        speed: 500,
        centeredSlides: true,
        preloadImages: false,
        lazy: {
          loadPrevNext: true,
        },
        navigation: {
          prevEl: "#main-prev",
          nextEl: "#main-next",
        },
        thumbs: {
          swiper: swiperThumbs,
        },
        on: {
          init: function() {
            this.lazy.loadInSlide(this.activeIndex - 2);
          },
        },
      }
    }
    new Swiper(document.querySelector("#swiper-main"), options);
  }
});
