/*!
 * Video
 */
const breakPoint = 768;
function isPC() {
  var w = window.innerWidth;
  if (w < breakPoint) {
    return false;
  } else {
    return true;
  }
}

// top
if (document.querySelector("#js-video-pc")) {
  const videoPc = document.querySelector("#js-video-pc"); //pc版のビデオタグ
  const videoPcSrc = videoPc.getAttribute("data-src"); //pc版のビデオタグのdata-src

  const videoSp = document.querySelector("#js-video-sp"); //sp版のビデオタグ
  const videoSpSrc = videoSp.getAttribute("data-src"); //sp版のビデオタグのdata-src

  let pcVideoBool = false;
  let spVideoBool = false;

  if (breakPoint <= window.innerWidth) {
    videoPc.src = videoPcSrc;
    pcVideoBool = true;
  } else {
    videoSp.src = videoSpSrc;
    spVideoBool = true;
  }

  window.addEventListener("resize", () => {
    //画面をresize時
    if (breakPoint <= window.innerWidth && !pcVideoBool) {
      videoPc.src = videoPcSrc;
      pcVideoBool = true;
    } else if (!spVideoBool) {
      videoSp.src = videoSpSrc;
      spVideoBool = true;
    }
  });
}

// salon
const mv = document.querySelectorAll(".movie");
document.addEventListener("DOMContentLoaded", () => {
  function isFF() {
    const agent = window.navigator.userAgent.toLowerCase();
    if (agent.indexOf("firefox") != -1) {
      return true;
    } else {
      return false;
    }
  }
  mv.forEach(function (vd, i) {
    vd.childNodes.forEach(function (el, i) {
      let is_playing = false;
      el.addEventListener("click", function () {
        this.classList.toggle("noplay");
        var _this = this;
        var video = _this.childNodes[0];

        if (!is_playing) {
          video.play();
          is_playing = true;
        } else {
          video.pause();
          is_playing = false;
        }
        video.addEventListener("ended", function () {
          if (_this.classList.contains("noplay")) {
            _this.classList.toggle("noplay");
          }
        });
      });
    });
  });
});

// document.addEventListener("DOMContentLoaded", () => {
//   function shuffle(items) {
//     var cached = items.slice(0),
//       temp,
//       i = cached.length,
//       rand;
//     while (--i) {
//       rand = Math.floor(i * Math.random());
//       temp = cached[rand];
//       cached[rand] = cached[i];
//       cached[i] = temp;
//     }
//     return cached;
//   }

//   var nodes = mv.children,
//     i = 0;
//   nodes = Array.prototype.slice.call(nodes);
//   nodes = shuffle(nodes);

//   mv.innerHTML = "";

//   while (i < mv.childElementCount) {
//     mv.appendChild(nodes[i]);
//     ++i;
//   }
// });
