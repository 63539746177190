/*!
 * Inview
 * use : in-view.min.js
 */

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".section,footer>*,.side-message").forEach((element) => {
    element.classList.add("inview");
  });
  inView(".inview").on("enter", function (el) {
    el.classList.add("is-inview");
  });
  // top header
  inView("#top-movie").on("enter", function (el) {
    document.querySelector("#header").classList.add("is-white");
  }).on("exit", function (el) {
    document.querySelector("#header").classList.remove("is-white");
  });
});

